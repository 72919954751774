<template>
	<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br/>山东泰克莱恩 0513-88762550</div>
</template>

<script>
	export default{
		
	}
</script>

<style scoped="scoped">
	.f{
		position: relative;
		background-color: #444;
		    padding: 10px 0 10px 0;
		    color: #fff;
		bottom: 0rem;
	}
</style>
